/* plotly scatterpolar (spider graph) */
#plotly-radar-chart{
  z-index: 3;
  margin: 0px 10px
}

.angular-axis{
  display: none
}

/* annotations */
.annotations{
  width: 500px;
}
.annotations .context-menu{
  width: 400px;
  padding: 20px 40px;
  margin-left: 140px;
  margin-top: 52px;
}
.annotation{
  background-color: #f8fafc;
  border-radius: 8px;
  margin-bottom: 16px;
  margin-top: 8px;
  font-size: 12px;
  padding: 8px 16px;
}
.annotation-dot{
  left: -8px;
}
.annotations textarea{
  width: calc(100% - 32px);
  padding: 8px 16px;
  height: 38px;
  background-color: #f8fafc;
  resize: none;
  border: 1px solid #CCCCCC;
  border-radius: 8px;
  margin-bottom: 22px;
}
.annotation-delete{
  margin-bottom: 12px;
  cursor: pointer;
}
.annotation-delete:hover{
  opacity: 0.8;
}

/* bouton back superadmin */
.button-superadmin{
  color: white;
  margin: auto 12px;
  cursor: pointer;
  text-decoration: underline;
}
.button-superadmin:hover{
  opacity: 0.8;
}

/* checklist-survey */
.checklist-survey{
  min-width: 300px;
  max-width: 300px;
  padding-right: 20px;
}
.checklist-survey-item{
  margin: 16px 0px;
  cursor: pointer;
}
.checklist-survey-item:hover{
  opacity: 0.8;
}
.checklist-survey-item svg{
  margin-right: 8px;
  font-size: 20px;
}

/* close-all popup */
.close-all{
  position : absolute;
  bottom : 50px;
  left : 10px;
  color : blue;
  text-decoration : underline;
  content :'';
  background-color : white;
  padding-left : 10px;
  padding-right : 10px;
  margin : 5px 6px ;
  border : 1px solid #e9e9e9;
  border-radius : 5px;
  box-shadow: 2px 5px #CCCCCC;
}

/* contact-phone */
.contact-phone{
  border-radius: 20px;
  background-color: #ffffff30;
}
.contact-phone img{
  height: 32px;
  width: 32px;
  margin-right: 12px;
}
.contact-phone span{
  margin: auto;
  margin-right: 18px;
  font-size: 12px;
}

.context-dot{
  margin: 5px;
  min-width: 10px;
  max-height: 10px;
  border-radius: 10px
}

.context-ref-button{
  height: 100%;
  width: 30px;
  padding: 0 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px
}

.context-ref-highlight{
  border-radius: 8px;
  top: -20px;
  position: absolute;
  z-index: 2;
  width: 60px;
  height: calc(100% + 20px)
}

/* contexts tabs */
.context-tabs-menu{
  margin: 0 20px;
}

.context-tab{
  background-color:white;
  border-radius: 8px;
  padding : 10px;
  margin-bottom: 10px;
}

.context-tab-active{
  box-shadow: inset 0 0 0 1px #111C2B;
}

.context-tab-inactive{
  box-shadow: inset 0 0 0 1px #b8b8b8;
}

.context-tab-open{
  background-color: white;
  z-index: 2;
  box-shadow: inset 0 0 0 1px #111C2B;
  padding: 10px
}

/* dash-button */
.dash{
  height: 187px;
  display : table;
  width : 100%;
  padding-bottom : 28px;
  table-layout : fixed;
  border-spacing : 10px 0
}


.dash-button{
  display : table-cell;
  font-size : 3em;
  text-align : center;
  color : #888888;
  border : 2px solid #ffffff;

}

.dash-button h2{
  height : 75px;
  color : #111C2B
}  

.dash-button p{
  position : absolute;
  color : red;
  width : 22px;
  background-color: #e9e9e9;
  border-radius : 12px;
  font-size : 20px;
  font-weight : 900;
  top: 62%;
  left: 50%;
  transform: translate(40%, -50%);
}

.dash-button:hover{
  border : 2px solid #444444;
  background-color: #e9e9e9;
  background-image: linear-gradient(to right, #e3f1e7 0%, #e9e9e9 100%);
  transform: scale(1.07);
  box-shadow: 2px 5px #444444
}

.dash-button:active{
	background-image:linear-gradient( #e9e9e9, #e3f1e7);
	text-shadow : 2px 2px 0px #444444 ;
	box-shadow: inset 2px 2px 2px 2px #444444;
}



/* checklist-account */
.checklist-account{
  width: 176px;
  margin-right: -12px;
  margin-bottom: -22px;
}

/* dashboard-compare */
.dashboard-compare{
  width: 1000px;
  margin-top: 22px;
}
.dashboard-compare .dropdown{
  width: 100%;
}
.dashboard-compare-bookmark{
  margin-top: 48px;
}
.dashboard-compare-separator{
  border-left: 1px solid;
  margin-left: 20px;
  margin-right: 20px;
}

/* dashboard-filter */
.dashboard-filter-chevron{
  margin-right: 6px;
}

.dashboard-filter-population{
  /*max-height : 326px;*/
  max-height : 200px;
  overflow-y : auto
}

/* tabs for the dashboard */
.dashboard-tab-container{
  width: 54px;
  margin-top : 8px;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}
.dashboard-tab{
  padding: 10px 20px;
  margin-right: -1px;
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
}
.dashboard-tab .dashboard-tab-text{
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
}
.dashboard-tab-open .dashboard-tab-text{
  overflow: initial;
}
.dashboard-tab svg{
  font-size: 18px;
  margin: 1px -2px;
  margin-bottom: 1px;
}
.dashboard-tab-open{
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}
.dashboard-tab-open .dashboard-tab{
  height: 11px;
}
.dashboard-tab-separator{
  border-left: 1px solid;
  height: 22px;
  top: 9px;
  right: 0px;
}
.dashboard-rounded-border{
  height: 8px;
  width: 8px;
  border-bottom-right-radius: 8px;
  border-bottom: 1px solid;
  border-right: 1px solid;
  bottom: 3px;
  background-color: white;
}
.dashboard-rounded-border-back{
  height: 8px;
  width: 8px;
  bottom: 2px;
}
.dashboard-rounded-border-fade{
  height: 1px;
  width: 100px;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(204,204,204,1) 100%);
  bottom: 3px;
}

/* details*/
.details-axis{
  padding: 20px 30px;
}
.details-axis img{
  width: 70px;
  height: 70px;
  margin: auto;
  margin-right: 18px;
}
.details-axis-note{
  font-size: 22px;
}
.details-axis-title{
  font-size: 16px;
  margin: 18px 0px;
}
.details-axis-title span{
  font-size: 14px;
}
.details-help{
  padding: 20px 30px;
  padding-top: 0px;
}
.details-topic-header-preview .details-topic-note{
  font-size: 24px;
  width: 64px;
}
.details-topic-note{
  width: 56px;
  font-size: 16px;
  margin : -2px 12px -4px 0px;
}
.details-topic-comment{
  font-size: 22px;
  padding-right: 12px
}
.details-topic-repartition{
  border-radius: 8px;
  overflow: hidden;
  height: 18px;
}
.details-topic-repartition-item{
  color: white;
  font-size: 12px;
  text-align: center;
  padding-top: 1px;
  height: 100%;
}
.details-topic-text{
  min-width: 150px
}
.detail-topic-card{
  width: calc(100% + 64px);
}
.detail-topic-card .card{
  cursor: initial;
}
.details-topic-no-participants{
  padding: 8px 0px 0px 280px;
}
.details-question-order{
  min-width: 132px;
  font-size: 12px;
  text-align: center;
  border: 1px solid;
  border-radius: 10px;
  margin: 0px 30px;
  height: 16px;
}
.details-question-item{
  text-align: center;
}
.details-question-icon{
  font-size: 28px;
  margin: -10px 0px;
}
.details-question-count{
  height: 120px;
}
.details-question-count-sm{
  height: 58px;
}
.details-question-count span{
  font-size: 12px;
}
.details-question-ratio{
  margin-top: 4px;
}
.details-question-label-container{
  height: 80px;
}
.details-question-label{
  width: 72px;
  font-size: 12px;
  margin: auto;
}
.details-question-trigger{
  font-size: 12px;
  margin-top: 18px;
  margin-bottom: 6px;
}
.details-proposition-container{
  width: 800px;
  margin-top: 12px;
}
.details-proposition{
  padding: 20px 60px 8px 0px;
}
.details-proposition-item{
  border: 2px solid #dadada;
  border-radius: 50%;
  padding: 2px;
  margin-right: 12px;
  height: 14px;
  width: 14px;
}
.details-proposition-item-center{
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #dadada;
}

/* edit-dashboard */
.edit-dashboard-tab{
  overflow-y: auto;
  height: 392px;
  margin: 0px -40px;
}
.edit-dashboard-checkbox-all{
  height: 0px;
  margin: -12px -4px 0px 2px;
}

/* editor-advanced */
.editor-contact{
  right: 20px;
  z-index: 1;
  top: 66px;
  position: fixed;
}
.editor-advanced-close{
  color: white;
  position: absolute;
  right: 40px;
  top: 20px;
  cursor: pointer;
  font-size: 24px;
}
.editor-advanced-close:hover{
  opacity: 0.8;
}

/* editor-axes-header */
.editor-axes-fields-options{
  position: absolute;
  right: 32px;
  padding-top: 20px;
}
.editor-axes .card{
  display: inline-block;
  margin-right: 20px;
  margin-left: -6px;
}
.editor-axes-header{
  min-height: 60px;
  width: calc(100vw - 80px);
}
.editor-axes-header-open{
  margin: auto 10px;
}
.editor-axes-header img{
  width: 60px;
  height: 60px;
  margin: 20px 26px 8px 26px;
}
.editor-axes-header h2{
  margin: auto;
}

/* editor-nav */
.editor-nav{
  width: 300px;
  height: calc(100vh - 48px);
  overflow-y: auto;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: white;
}
.editor-nav-pastille .podium-pastille{
  margin-top: 3px;
  border: 1px solid white;
}
.editor-nav-axis-caret{
  margin-right: -14px;
}
.editor-nav-axis{
  border: 1px solid;
  border-radius: 8px;
  padding: 10px;
  margin: 20px;
}
.editor-nav-axis-button .link{
  margin: -14px 20px 0px 20px !important;
  font-size: 12px;
}
.editor-nav-topic{
  background-color: #c1c1c1;
  font-size: 12px;
  border-radius: 8px;
  margin-top: 6px;
  color: white;
  cursor: pointer;
  position: relative;
  border: 1px solid #efefef;
  padding: 6px 8px;
  width: calc(100% - 20px);
}
.editor-nav-topic:hover{
  opacity: 0.8;
}

/* editor-question */
.editor-question-floating-menu{
  position: absolute;
  width: 40px;
  margin-left: -40px;
}
.editor-question-floating-menu .question-action{
  background-color: initial;
  box-shadow: initial;
  color: white;
}
.editor-question-floating-menu .question-action:hover{
  opacity: 0.8;
}
.editor-question-dot{
  top: 92px;
  border: 1px solid white;
  left: 20px;
  z-index: 4;
}
.editor-question-hide-nspp{
  font-size: 12px;
  color: white;
}
.editor-question-hide-nspp .checkbox{
  border-color: white;
  background-color: initial;
}
.editor-question-new-topic{
  color: white;
  border: 1px solid white;
  border-radius: 8px;
  padding: 12px;
  text-align: center;
  width: 184px;
}
.editor-question-new-topic .button{
  margin-top : 16px;
}
.editor-question-new-topic .link{
  margin-top : 8px !important;
  font-size: 12px;
}

/* editor-topics */
.editor-topic-without-score .list-dropdown-text, 
.editor-topic-without-score span{
  color: #8C8C8C;
}
.editor-topic-reverse .list-dropdown-text, .editor-topic-reverse span{
  color: #eb5a46;
  font-weight: bold;
}
.editor-topics-secondary{
  width: 0px;
}
.editor-topics-actions{
  position: fixed;
  z-index: 9;
}
.editor-topics-secondary-dot{
  height: 11px;
  width: 11px;
  position: absolute;
  border-radius: 50%;
  left: 13px;
  bottom: 2px;
}
.editor-topics-secondary-top{
  height: calc(100% + 17px);
  border-left: 1px solid;
  position: absolute;
  left: 18px;
  bottom: 2px;
}
.editor-topics-secondary-bottom{
  height: 22px;
  border-left: 1px solid;
  position: absolute;
  left: 18px;
  bottom: -18px;
}

/* email skin */
.email-skin-preview{
  margin: 10px;
  width: 170px;/*144px;*/
  height: 233px;
  border-radius: 8px;
  float: left;
}

/* filtres */
.filters{
  margin-bottom: 18px;
  margin: 18px 0px;
}
.filters-item{
  border-radius: 20px;
  padding: 8px 12px;
  margin: 6px 12px 6px 0px;
  border: 1px solid #CCCCCC;
  color : #8C8C8C;
  white-space: nowrap;
}
.filters-item-container{
  position: relative;
}
.filters-item-error-txt{
  font-size: 12px;
  margin-left: 6px;
}
.filters-item-active{
  border: 2px solid #8C8C8C;
  margin: 4px 12px 4px 0px;
  color : initial;
  background-color: white;
  cursor: pointer;
}
.filters-item-error{
  border-color: #eb5a46;
  color: #eb5a46 !important;
}
.filters-item-activable:hover{
  background-color: #F0F0F0;
  cursor: pointer;
}
.filters-icon-right{
  margin-right: 6px;
}
.filters-icon-left{
  margin-left: 6px;
}
.filters input{
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  width: 100px;
  margin: -4px 0px;
}
.filters-context{
  position: absolute;
  top: 48px;
  left: 6px;
  z-index: 9;
}
.filters-context-custom .context-menu{
  width: 438px;
}
.filters-context-text{
  padding : 0px 20px;
}
.filters-reset-icon{
  cursor: pointer;
  margin-left: 6px;
}
.filters-reset-icon:hover{
  opacity: 0.8;
}

/* heatmap */
.dashboard-heatmap-separator{
  border-top: 2px solid #F0F0F0;
  width: 100%;
  position: absolute;
  transform: rotate(18deg);
  top: 65px;
  left: -12px;
}
.heatmap-randomize{
  z-index: 2;
  position: fixed;
  margin: 8px 12px;
  right: 0px;
  top: 54px;
}
.heatmap-header{
  display:contents;
  height: 130px;
  position: sticky;
  top:-20px;
  z-index: 3;
}
.heatmap-column{
  position: relative;
}
.heatmap-column-text{
  transform: rotate(-45deg);
  position: absolute;
  width: 130px;
  height: 32px;
  overflow: hidden;
  bottom: 54px;
  z-index: 1;
  text-align: initial;
}
.header-loader .load-container{
  width: 100vw;
  margin-top: 100px;
}
.heatmap-container .card{
  width: 100vw;
  height: calc(100vh - 48px);
}
.heatmap-container-bookmark{
  margin-top: 42px;
}
.heatmap-container-bookmark .card{
  height: calc(100vh - 88px);
}
.heatmap-column-text span{
  margin-top: auto;
}
.heatmap-cell{
  min-width: 52px;
  text-align: center;
}
.heatmap-cell-company{
  margin-left: 60px;
}
.heatmap-dropdown{
  padding: 4px 32px;
}
.heatmap-data{
  width: fit-content;
  min-width: 100%;
}

/* import modal */
.import-modal{
  text-align: center;
}

/* menu account */
.menu-accounts-container{
  width: 54px;
  height: 100%;
  position: fixed;
  z-index: 10;
}
.menu-accounts-circle{
  margin: 0px 8px;
  max-width: 36px;
  min-width: 36px;
  max-height: 36px;
  min-height: 36px;
  border-radius: 50%;
}
.menu-accounts-superadmin{
  border-radius: 8px;
  margin-top: -1px;
}
.menu-account-separator{
  border-bottom: 1px solid;
  width: 32px;
  margin: auto;
  margin-top: 14px;
  margin-bottom: 14px;
}
.menu-accounts{
  width: 52px;
  position: absolute;
  border-right: 1px solid;
  background-color: #ebebeb;
  height: 100%;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
}
.menu-accounts-name{
  font-size: 12px;
  margin: auto 4px;
  display: none;
  min-width: 100px;
}
.menu_account-expanded{
  width: 160px;
  transition: all 0.5s cubic-bezier(0.5, -0.005, 0.2, 1);
  -webkit-transition: all 0.5s cubic-bezier(0.5, -0.005, 0.2, 1);
}
.menu_account-expanded .menu-accounts-name{
  display: initial;
  animation: menuAccountFadeIn 1s;
}
.menu-accounts-line{
  margin-top: 8px;
}
.menu-accounts-circle.menu-accounts-add{
  border: 1px solid;
  font-size: 18px;
}
.menu-accounts-add svg{
  margin: auto;
}
@keyframes menuAccountFadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
.menu-accounts-scrollbar{
  overflow-y: auto;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.menu-accounts-scrollbar::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.message-letter-content{
  margin: 20px 0px;
}
.message-letter-content textarea{
  resize: none;
  height: 232px;
  border: none;
  padding: 10px 14px;
  border-radius: 8px;
  width: calc(100% - 8px);
  font-size: 14px;
  border: 1px solid;
}
.message-letter-cta{
  padding: 6px 10px;
  box-shadow: 1px 1px 4px #8b8b8b;
}

/* message sms */
.message-sms-sender{
  position: absolute;
  width: 220px;
  top: 62px;
  left: 40px;
  text-align: center;
  border-bottom: 1px solid;
  padding: 14px 0px;
}
.message-sms-body{
  position: absolute;
  top: 120px;
  left: 50px;
  font-size: 12px;
  width: 132px;
  padding: 10px 16px;
  border-radius: 0px 18px 18px 18px;
}
.message-sms-body span{
  text-decoration: underline;
  color: #0079bf;
  margin-left: 4px;
}
.message-sms-background{
  position: absolute;
  top: 62px;
  left: 40px;
  background-color: white;
  height: 388px;
  width: 218px;
}

/* widget messages */
.messages-img{
  width: 100px;
  height: 100px;
  margin-left: 100px;
  margin-bottom: 32px;
}
.messages-card .context-menu{
  left: -140px !important;
}
.messages-colors{
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin: 12px 6px;
  cursor: pointer;
}
.messages-colors svg{
  color: #8C8C8C;
  margin: auto;
}
.messages-colors-active{
  background-color: white;
  height: 10px;
  width: 10px;
  margin: 7px;
  border-radius: 50%;
}
.messages-color:hover{
  opacity: 0.8;
}
.message-axis{
  padding : 6px 50px;
}
.messages-card{
  background-color: white;
  border-top: 1px solid #eaeaea;
  padding: 20px 40px;
}
.messages-card .color-picker{
  margin: 16px;
  height: 52px;
}
.message-user{
  font-size: 32px;
  margin-right: 18px;
}
.message-text{
  margin-top: 6px;
}
.message-text mark{
  background-color: #ffab4a;
}

/* nav focus action button */
.nav-focus-action svg{
  font-size: 16px;
  margin: -1px 1px;
}

/* owl wizard */
.owl-wizard{
  z-index: 2;
  background-color: #ffffffdd;
  border-radius: 8px;
  padding: 10px;
  /*border: 1px solid #111C2B*/
}
.owl-wizard-speech-ballon{
  background-color: white;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #111C2B
}

/* participants */
.participants-email{
  margin: 8px;
  background-color: white;
  padding: 2px 10px;
  color: #8C8C8C;
  border: 1px solid #8C8C8C;
  border-radius: 12px;
}
.participants-others{
  margin: 10px;
  text-decoration: underline;
  cursor: pointer;
}
.participants-others:hover{
  opacity: 0.8;
}

/* participation */
.participation-item{
  text-align: center;
  font-size: 12px;
  margin: 6px 0px;
}
.participation-item svg{
  font-size: 32px;
  margin: 8px;
}
.participation-item span{
  color: #8C8C8C;
  font-size: 14px;
  line-height: 18px;
}

/* podium */
.podium-title{
  margin : 22px 0px 12px 0px;
}
.podium-text{
  font-size: 12px;
  margin: auto;
  padding-right: 16px;
  text-align: right;
  width: 120px;
}
.podium-text-label{
  cursor: pointer;
  margin: auto;
}
.podium-text-label:hover{
  opacity: 0.8;
}
.podium-note{
  min-width: 30px;
  padding: 6px 0px;
  font-size: 16px;
}
.podium-pastille{
  min-width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: auto;
  margin-right: 6px;
}

/* calendar */
.rbc-toolbar .rbc-toolbar-label{
  text-align: right !important;
  font-size: 14px;
  font-weight: bold;
}
.rbc-month-view{
  border: none !important;
}
.rbc-header{
  border-left: none !important;
  color: #8C8C8C;
  padding-bottom: 4px !important;
}
.rbc-day-bg{
  cursor: pointer;
}
.rbc-day-bg:hover{
  background-color: #e6e6e6 !important;
}
.rbc-date-cell{
  text-align: center !important;
  color: #CCCCCC;
  font-weight: bold;
  padding-right : 0px !important;
}
.rbc-event, .rbc-day-slot .rbc-background-event{
  font-size: 11px;
  border-radius: 0px !important;
  padding: 2px 4px !important;
  position: absolute;
  width: 90px !important;
  margin: 0px !important;
  margin-top: -20px !important;
  height: 78px;
}
.rbc-event:hover{
  opacity: 0.8;
}
.rbc-ellipsis, .rbc-event-label, .rbc-row-segment .rbc-event-content, .rbc-show-more{
  white-space: initial !important;
  text-align: center;
  height: 100%;
}

/* resources*/
.resources-item{
  width: 312px;
  margin-right: 32px;
  margin-bottom: 26px;
}
.resources-item img{
  width: 312px;
  object-fit: cover;
  height: 150px;
  border-radius: 8px 8px 0px 0px;
  cursor: pointer;
}
.resources-item img:hover{
  opacity: 0.8;
}
.resources-content{
  padding: 20px 30px;
}
.resources-footer{
  margin-top: 24px;
  margin-bottom: 12px;
}

/* répartition */
.repartition{
  margin: 10px;
  width: 410px;
  height: 210px;
}
.repartition-item{
  font-size: 12px;
  border-top: 1px solid #dadada;
  text-align: center;
  height: 38px;
  padding: 12px;
}
.repartition-bar{
  background-color: #f5f5f5;
  margin: auto;
  height: 110px;
  width: 22px;
  border-radius : 8px 8px 0px 0px;
}
.repartition-bar-content{
  text-align: center;
  font-size: 12px;
  width: 100%;
}

/* report generator widget */
.report-generator .card{
  margin-bottom: 0
}
.report-generator .detail-topic-card{
  width: 100%
}
.report-screenshot-podium{
  margin-bottom: 8px;
  text-align: center;
  padding: 2px;
  border-radius: 8px;
}

.report-page-layout{
  border: 1px solid;
    margin: 4px;
    border-radius: 4px;
}

.report-nav{
  position: fixed;
  background-color: white;
  width: calc(100% - 450px);
  top: 48px;
  left: 0px;
  box-shadow: 0px 0px 4px #d4d4d4;
  overflow-x: scroll;
  padding: 10px 20px;
  z-index: 1;
  overflow-y: hidden;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

.report-nav.report-nav-params-closed{
  width: calc(100% - 40px);
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

.report-edit-page-right{
  width: 410px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

.report-edit-page-right.report-edit-page-right-params-closed{
  width: 0px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

.report-nav-page{
  height: 120px;
  max-width: 200px;
  min-width: 200px;
  border-radius: 8px;
  border: 1px solid;
  margin: 10px 0px;
  position: relative;
}

.report-nav-page-active{
  border: 3px solid;
  margin: 8px 0px;
}

.report-nav-backgrounds .report-nav-page-active{
  margin: 2px !important;
}

.report-nav-backgrounds .report-nav-page{
  height: 34px;
  margin: 4px;
  max-width: 56px;
  min-width: 56px;
}

.report-nav-background-generating{
  border: none;
}

.report-nav-page-delete{
  background-color: white;
  left: 180px;
  top: 14px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  opacity: 0.25;
}

.report-nav-page-add{
  padding: 10px;
}

.report-nav-page-add .report-nav-page-add-content{
  width: 0px;
  opacity: 0;
  transition: all 0.3s ease-out;
}

.report-nav-page-add:hover .report-nav-page-add-content{
  width: 60px;
  opacity: 1;
  transition: all 0.3s ease-out;
}

.report-nav-page-add:hover{
  font-size: 40px;
}

.report-nav-background-active svg{
  border-radius: 6px;
  height: 34px;
}

.report-nav-background-inactive svg{
  border-radius: 7px;
  opacity: 0.5;
}

.report-nav-page-title{
  position: absolute;
  background-color: white;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  bottom: 0px;
  width: calc(100% - 12px);
  padding: 2px 6px;
  border-radius: 0px 0px 7px 7px;
}

.report-edit-container{
  top: 222px;
  left: 0px;
  position: fixed;
  height: calc(100% - 222px);
  width: 100%;
}

.report-edit-page{
  width: 720px;
  height: 432px;
  border: 1px solid;
  border-radius: 8px;
}

.report-page-preview{
  padding : 10px;
  height: -webkit-fill-available;
  overflow: hidden;
}

.report-page-preview .card{
  background-color: transparent;
  box-shadow: none;
}

.report_page_image{
  height: auto;
  width: 75%;
  margin: auto;
  padding: 2px;
}

.report_page_image img{
  width: 100%;
  height: auto;
}

.report-screenshot-podium-sm{
  margin-top: -40px;
}

.report-screenshot-podium-sm .podium-note{
 padding: 4px 0px 4px 8px;
}

.report-page-preview-background{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: -1;
  background-color: white;
}

.report-page-preview-background svg{
  border-radius : 6px
}

.report-params{
  position: fixed;
  width: 350px;
  background-color: white;
  box-shadow: 0px 2px 8px #adadad;
  padding: 16px 30px;
  overflow-y: auto;
  height: calc(100% - 80px);
  top: 48px;
  right: 0px;
  z-index: 1;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  padding-left: 38px;
  padding-right: 22px;
}

.report-params .context-menu{
  width: 200px;
}

.report-page-editor .rdw-editor-toolbar{
  position: absolute;
  border: none;
  top: 4px;
  border-radius: 8px;
  left: 80px;
  background: none;
}

.report-page-editor .rdw-option-wrapper, .report-page-editor .rdw-dropdown-wrapper{
  border-color: #CCCCCC;
  border-radius: 8px;
}

.report-page-editor .rdw-option-active{
  border-color: #111C2B;
}

.report-page-content-type{
  background-color: white;
  right: 0px;
  padding: 6px;
  border-radius: 6px 6px 0px 0px;
  top: 0px;
  width: calc(100% - 12px);
}

.report-page-content{
  margin : auto 0px;
  min-height: 100px;
  width: 100%;
}

.report-page-editor-textarea{
  background-color: #8080800d;
}

.report-page-params{
  background-color: white;
  border-radius: 0px 8px 0px 0px;
  margin: -10px -10px -10px 10px;
  padding: 4px;
}

.report-params-arrow{
  top: 0px;
  left: 0px;
  height: 100%;
  width: 14px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

.report-edit-container-arrow{
  padding: 16px;
  font-size: 32px;
}

.report-params-arrow.report-params-arrow-closed{
  width: 28px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

.report-page-preview .rdw-editor-main{
  overflow: hidden;
}

.report-params-arrow svg{
  padding: 2px;
  font-size: 12px;
}

.report-params.report-params-closed{
  right: -382px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

.report-page-text{
  white-space: pre-line;
  margin: auto; 
}

.report-page-editor-toolbar .list-dropdown{
  border: 1px solid #CCCCCC;
  margin: 2px 4px;
  font-size: 12px;
  padding: 1px 6px;
  border-radius: 8px;
}

/* widget résultat */
.results-tree{
  position: relative;
  margin: auto;
}
.results-tree-back{
  position: absolute;
}
.results-tree-text{
  position: absolute;
  font-size : 9px;
  align-items : center;
  padding-right : 0px;
  z-index: 2;
  pointer-events: none;
}
.results-tree-text span{
  pointer-events: initial;
}
.results-tree-pastille{
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.results-tree-border-container{
  position: absolute;
  z-index: 1;
}
.results-tree-border{
  border-top: 2px solid white;
}
.results-tree-center{
  position: absolute;
  text-align: center;
  background-color: white;
  z-index: 2;
  width: 76px;
  height: 76px;
  border-radius: 50%;
  line-height: 22px;
}
.results-tree-note{
  padding-top: 20px;
  font-size: 28px;
}
.results-tree-center svg{ 
  font-size: 32px;
}
.results-tree-center span{
  font-size: 10px;
}
.results-nightmare-mode{
  position: absolute;
  right: -8px;
  top: -54px;
  z-index: 4;
}
.results-nightmare-mode .context-menu{
  right: 0px;
}
.results-nightmare-mode-cog{
  margin: auto;
  cursor: pointer;
  opacity: 0.5;
}
.results-nightmare-mode-cog:hover{
  opacity: 0.8;
}
.results-axis{
  text-align: center;
  width: 50%;
  border-radius: 4px;
  cursor: pointer;
}
.results-axis-icon{
  height: 46px;
  margin: 6px;
}
.results-axis-icon svg{
  margin: auto;
  font-size: 32px;
}
.results-axis:hover{
  background-color: #F9FBFC;
}
.results-axis-img{
  width: 26px !important;
  height: 26px;
  margin: auto 0px;
}
.results-axis-text{
  font-size: 12px;
  margin: auto 6px;
}
.results-axis-note{
  font-size: 20px;
  margin: auto 6px;
}
.results-benchmark{
  font-size: 12px;
  text-align: center;
}

/* widget rps */
.rps-container li{
  text-align: initial;
}
.rps-text{
  height: 40px;
}
.rps-text span{
  margin: auto;
  width: 120px;
}
.rps-axis{
  color: #8e8e8e;
  position: absolute;
  font-size: 18px;
}
.rps-info-help svg{
  margin: 0px;
  margin-left: 4px;
  cursor: pointer;
  font-size: 12px;
  color : #8e8e8e;
}
.rps-info-help svg:hover{
  opacity: 0.8;
}
.rps-axis-x{
  position: absolute;
  width: 100%;
  bottom: -26px;
  text-align: center;
}
.rps-axis-y{
  position: absolute;
  transform: rotate(-90deg);
  top: 100px;
  left: -100px;
}
.rps-area span{
  margin: auto;
  width: 60px;
  text-align: center;
}
.rps-area{
  opacity: 0.6;
}
.rps-user{
  min-width: 4px;
  min-height: 4px;
  background-color: grey;
  position: absolute;
  border-radius: 50%;
}
.rps-recommandation{
  padding-top: 2px;
}
.rps-recommandation span{
  margin: -3px 4px 0px 4px;
}
.rps-user-label{
  text-align: center;
  font-size: 12px;
  padding: 0px 20px;
}
.rps-user-label-border{
  min-width: 12px;
  border-right: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
  margin: 6px;
}
.rps-user-item{
  margin: 14px 0px;
}
.rps-user-item-label{
  font-size: 12px;
  padding-left: 18px;
}
.rps-user-item-label p{
  margin: 0px;
  font-size: 12px;
}
.rps-user-item-label ul{
  margin: 0px;
}

/* self */
.self-axis img{
  width: 40px;
  height: 40px;
  margin: auto 20px;
}
.self-axis svg{
  margin: auto 26px;
  font-size: 22px; 
}
.self-question{
  width: 260px;
  margin-right: 20px;
}

/* Debut ajout tab select*/
.tab-select{
  position : absolute;
  padding:0;
  top : 145px;
  right : 0;
}

.tab-select>div{
  position : relative;
  display : table-cell;
  text-align : center;
  padding: 0;
}
/*Fin ajouts*/

/* tasks */
.modal-help-large .tasks-list-draft .card{
  width: 730px;
}
.tasks-column{
  margin: 0px 10px;
  border: 1px solid;
  border-radius: 8px;
}

.tasks-column-container{
 margin: 0px -10px;
}

.tasks-column-title{
  border-radius: 6px 6px 0px 0px;
  padding: 12px 20px;
  font-size: 18px;
}

.tasks-column-card-container{
 padding: 16px 16px 0px 16px;
}

.tasks-column-card-container .card{
  margin-bottom: 16px;
}

.tasks-date{
  padding: 10px 20px;
  background-color: white;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 0px 10px #c9c9c9;
  margin-left: 20px;
}

.tasks-date-title{
  font-size: 12px;
}

.tasks-date-month{
  font-size: 18px;
}

.tasks-message{
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 6px 12px
}
.tasks-message-footer{
  font-size: 12px;
}

/* liste des équipes */
.teams-container{
  width: 250px;
}
.teams-team-name{
  margin-top: 22px;
}
.teams-container #teams{
  position: fixed;
}
@media screen and (max-width: 1050px) {
  .teams-container #teams{
    position: initial;
  }
}
.teams{   
  margin-top: 8px;
  width: 210px;
  position: relative;
  padding: 8px;
}
.teams-scroller{
  height: calc(100vh - 210px);
  overflow-y: auto;
}
.teams-scroller::-webkit-scrollbar { 
  display: none; 
}
.teams-item{    
  cursor: pointer;
  padding: 12px 8px;
  border-radius: 4px;
  font-size: 12px;
}
.teams-item-icon{
  width: 14px;
}
.teams-item-icon svg{
  font-size: 16px;
}
.teams-item-icon svg:hover{
  opacity: 0.8;
}
.teams-item:hover{
  background-color: #F0F0F0;
}
.teams-inactive{
  background-color: #f9fbfccf;
  position: absolute;
  height: 100%;
  width: calc(100% + 2px);
  top: 0px;
  left: 0px;
  z-index: 4;
}
.teams .text-input-context{
  font-size: 12px !important;
  min-width: 120px !important;
  color: #8C8C8C;
  margin-bottom: 0px !important;
  padding: 5px 4px !important;
}
.teams .context-menu{
  right: -130px;
  width: 178px;
  top: 16px;
}
.teams-order-label{
  font-size: 12px;
  margin-top: 20px;
}

/*template-lang-icons*/
.template-lang-icons{
  cursor: pointer;
  width: 100px;
  height: 60px;
  overflow: hidden;
  margin-bottom: -60px;
  bottom: 170px;
  left: 20px;
  z-index: 10
}

/*upload-button (darg & drop zone)*/
.upload-button{
  border : 2px;
  border-radius : 8px;
  border-style : dotted;
  height: 350px;
  margin : 3px;
  padding : 10px;
}

.upload-button-drop{
  background-color : #dadada
}

/* upgrade-widget */
.upgrade-widget img{
  border-radius: 8px;
}
.upgrade-widget-card{
  min-width: 400px;
  max-width: 450px;
}
.upgrade-widget-card img{
  width: 220px;
  margin-right: 12px;
  cursor: pointer;
  border-radius: 8px;
}
.upgrade-widget-card span{
  font-size: 12px;
}